import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastConfig } from '../types/toast-config';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private subject = new Subject<ToastConfig>();
  private observable = this.subject.asObservable();

  constructor() { }

  observe(): Observable<ToastConfig> {
    return this.observable;
  }

  setToastConfig(config: ToastConfig) {
    this.subject.next(config);
  }

}
