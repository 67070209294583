import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastService } from './toast.service';

import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApplicationObj } from '../types/application-type';
import { ElaborationData } from '../types/elaborations';
import { ToastType } from '../types/toast-config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly translocoService: TranslocoService,
    private readonly toastService: ToastService
  ) { }

  private readonly DEV_URL = 'http://localhost:4200';

  getBaseUrl() {
    const origin = window.location.origin;
    return (environment.production === false && origin.includes(this.DEV_URL)) ? environment.api : origin;
  }

  getTokenByCode(code: string): Observable<any> {
    const url = `${environment.cognito.url}/oauth2/token`;
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('redirect_uri', window.location.origin)
      .set('client_id', environment.cognito.client_id);

    return this.http
      .post(url, body.toString(), { headers: headers })
      .pipe(this.handleErrorWithToast());
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.authService.retrieveRefreshTokenFromStorage();


    const url = `${environment.cognito.url}/oauth2/token`;
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('redirect_uri', window.location.origin)
      .set('client_id', environment.cognito.client_id);

    return this.http
      .post(url, body.toString(), { headers: headers })
      .pipe(this.handleErrorWithToast());
  }

  getApplications(): Observable<ApplicationObj[]> {
    let url = `${this.getBaseUrl()}/api/base/applications`;
    url = this.setLanguage(url);

    const headers = this.getHeaders();
    return this.http
      .get<ApplicationObj[]>(url, { headers: headers })
      .pipe(this.handleErrorWithToast());
  }

  getElaborations(
    page: number = 1,
    pageSize: number = 10,
    direction: string = 'desc'
  ): Observable<ElaborationData> {
    let url = `${this.getBaseUrl()}/api/rendite/elaborations?page=${page}&pageSize=${pageSize}&direction=${direction}`;
    url = this.setLanguage(url);
    const headers = this.getHeaders();

    return this.http
      .post<ElaborationData>(url, {}, { headers: headers })
      .pipe(this.handleErrorWithToast());
  }

  downloadDocument(keyOutput: string): Observable<any> {
    const url = `${this.getBaseUrl()}/api/rendite/download-url?key=${keyOutput}`;
    const headers = this.getHeaders();

    return this.http
      .get<any>(url, { headers })
      .pipe(this.handleErrorWithToast());
  }

  uploadDocument(file: File) {
    const now = Date.now();
    const fullFileName = file.name.split('.');
    const fileName = `${fullFileName[0]}_${now}.${fullFileName[1]}`;

    return this.getPresignedUrl(fileName).pipe(
      switchMap((res: { url: string }) => this.putFileInS3Bucket(res.url, file))
    );
  }

  private getPresignedUrl(filename: string): Observable<any> {
    const url = `${this.getBaseUrl()}/api/rendite/upload-url?fileName=${filename}`;
    const headers = this.getHeaders();

    return this.http
      .post<string>(url, {}, { headers })
      .pipe(this.handleErrorWithToast());
  }

  private putFileInS3Bucket(url: string, file: File): Observable<any> {
    const headers = {
      'Content-Type': file.type,
      'Content-Length': '' + file.size,
    };

    return this.http
      .put(url, file, { headers })
      .pipe(this.handleErrorWithToast());
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders().set(
      'Authorization',
      this.authService.retrieveAuthFromStorage()['id_token']
    );
  }

  private setLanguage(url: string): string {
    const updatedUrl = new URL(url);
    updatedUrl.searchParams.set(
      'locale',
      this.translocoService.getActiveLang()
    );
    return updatedUrl.toString();
  }

  private handleErrorWithToast<T>() {
    return (source: Observable<T>) =>
      source.pipe(
        catchError((error) => {
          this.toastService.setToastConfig({
            message: `http.${error.status}`,
            type: ToastType.ERROR,
          });
          return throwError(() => error);
        })
      );
  }
}
