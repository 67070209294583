export const environment = {
  production: false,
  api: 'https://pandorica.dev.leithub.io',
  rendite: {
    lowerLimitByteSize: 0,
    warningByteSize: 85000,
    upperLimitByteSize: 170000,
  },
  cognito: {
    url: 'https://pan-dev-main.auth.eu-west-1.amazoncognito.com',
    client_id: '7abr8eoiqubtp3380ab44ild5c',
  },
};
