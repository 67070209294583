export interface ToastConfig {
  message: string;
  type: ToastType;
  position?: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';
}

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export const TOAST_STYLE_MAP = {
  [ToastType.SUCCESS]: {
    icon: 'check_circle',
    background: 'bg-green-100',
    border: 'border-green-500',
    color: 'text-green-500',
  },
  [ToastType.ERROR]: {
    icon: 'error',
    background: 'bg-red-300',
    border: 'border-red-600',
    color: 'text-red-600',
  },
  [ToastType.WARNING]: {
    icon: 'warning',
    background: 'bg-yellow-100',
    border: 'border-yellow-500',
    color: 'text-yellow-500',
  },
  [ToastType.INFO]: {
    icon: 'info',
    background: 'bg-blue-100',
    border: 'border-blue-500',
    color: 'text-blue-500',
  },
};
