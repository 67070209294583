import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  saveItem(key: string, item: any, storageType: STORAGE_TYPE = STORAGE_TYPE.SESSION_STORAGE): void {
    const itemString = (typeof item === 'string') ? item : JSON.stringify(item);
    const storage = this.chooseStorage(storageType)
    storage.setItem(key, itemString);
  }

  getItem<T>(key: string, storageType: STORAGE_TYPE = STORAGE_TYPE.SESSION_STORAGE): T | null {
    const storage = this.chooseStorage(storageType)
    const itemString = storage.getItem(key);

    if (!itemString) {
      return null;
    }

    return JSON.parse(itemString) !== null ? JSON.parse(itemString) : itemString;
  }

  updateItem(key: string, newItem: any, storageType: STORAGE_TYPE = STORAGE_TYPE.SESSION_STORAGE): void {
    return this.saveItem(key, newItem, storageType);
  }

  deleteItem(key: string, storageType: STORAGE_TYPE = STORAGE_TYPE.SESSION_STORAGE): void {
    const storage = this.chooseStorage(storageType)
    storage.removeItem(key);
  }

  clear(storageType: STORAGE_TYPE = STORAGE_TYPE.SESSION_STORAGE) {
    const storage = this.chooseStorage(storageType)
    storage.clear();
  }

  private chooseStorage(storageType: STORAGE_TYPE): Storage {
    if (storageType === STORAGE_TYPE.SESSION_STORAGE) return sessionStorage;
    return localStorage;
  }
}

export enum STORAGE_TYPE {
  SESSION_STORAGE = 'SESSION_STORAGE',
  LOCAL_STORAGE = 'LOCAL_STORAGE'
}