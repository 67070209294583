import { Injectable } from '@angular/core';
import { map, Observable, pluck } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from '../../environments/environment';
import { Auth } from '../types/auth';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly AUTH_KEY: string = 'auth';
  readonly REFRESH_TOKEN: string = 'refresh_token';

  readonly LOGIN_URL: string = `${environment.cognito.url
    }/oauth2/authorize?client_id=${environment.cognito.client_id
    }&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(
      window.location.origin
    )}`;

  private auth$: BehaviorSubject<Auth> = new BehaviorSubject<Auth>({
    isLogged: false,
  });

  constructor(private readonly storage: StorageService) { }

  isLogged(): Observable<boolean> {
    return this.auth$.pipe(pluck('isLogged'));
  }

  saveAuthInStorage(auth: any): void {
    this.storage.saveItem(this.AUTH_KEY, auth);
  }

  retrieveAuthFromStorage(): any {
    return this.storage.getItem<any>(this.AUTH_KEY);
  }

  retrieveRefreshTokenFromStorage(): any {
    return this.storage.getItem<any>(this.AUTH_KEY)[(this.REFRESH_TOKEN)];
  }

  retrieveAuthInfo(): Observable<object | null> {
    return this.auth$.pipe(
      pluck('tokens'),
      map((tokens: any) => {
        if (tokens?.['id_token']) {
          const parts = tokens?.['id_token'].split('.');

          if (parts.length !== 3) {
            return null; //token non valido
          }

          const payload = parts[1];
          const decodedPayload = atob(payload);
          return JSON.parse(decodedPayload);
        } else {
          return null;
        }
      })
    );
  }

  goToLogin(): void {
    window.location.href = this.LOGIN_URL;
  }

  updateAuthentication(auth: Auth, delayTime: number = 0) {
    setTimeout(() => this.auth$.next(auth), delayTime);
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const cookieParts = cookie.split('=');
      const cookieName = cookieParts[0].trim();

      document.cookie =
        cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    }
  }

  logout() {
    this.storage.clear();
    this.deleteAllCookies();
  }
}
